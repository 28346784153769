import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import AssignPreparer from './AssignPreparer';
import RouteWriter from 'core/domain/model/route/write/RouteWriter';
import Route from 'core/domain/model/route/write/Route';

class AssignPreparerHandler implements CommandHandler<AssignPreparer> {
  private readonly routeWriter: RouteWriter;
  eventBus!: EventBus;

  public constructor(routeWriter: RouteWriter) {
    this.routeWriter = routeWriter;
  }

  public async execute(command: AssignPreparer): Promise<void> {
    const route = Route.assignPreparer(command.preparerId, command.segment);
    await this.routeWriter.assignPreparerToRoute(route);
    this.eventBus.publish([...route.recordedEvents()]);
  }
}

export default AssignPreparerHandler;
