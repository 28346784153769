import { JwtToken } from '../JwtToken';

enum EmployeeRoles {
  INVENTORY_USER = 'inventory_user',
  RETURNS_USER = 'WH_RETURNS_USER',
  UNSOLD_USER = 'OPS_LOGISTICS_UNSOLD',
  OPS_LOGISTICS_TESTER_USER = 'OPS_LOGISTICS_TESTER_USER',
  OPS_WH_MEN = 'OPS_WH_MEN',
}

class EmployeeView {
  readonly id: string;
  readonly authToken: JwtToken;
  readonly username: string;
  readonly roles: string[];

  constructor({
    id,
    authToken,
    username,
    roles,
  }: {
    id: string;
    authToken: string;
    username: string;
    roles: string[];
  }) {
    this.id = id;
    this.username = username;
    this.roles = roles;
    this.authToken = authToken;
  }

  canInventory(): boolean {
    return this.roles.includes(EmployeeRoles.INVENTORY_USER);
  }

  canManageReturns(): boolean {
    return this.roles.includes(EmployeeRoles.RETURNS_USER);
  }

  canUnsold(): boolean {
    return this.roles.includes(EmployeeRoles.UNSOLD_USER);
  }

  canTester(): boolean {
    return this.roles.includes(EmployeeRoles.OPS_LOGISTICS_TESTER_USER);
  }

  canShowMen(): boolean {
    return this.roles.includes(EmployeeRoles.OPS_WH_MEN);
  }
}

export default EmployeeView;
