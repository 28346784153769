import AggregateRoot from 'shared/domain/AggregateRoot';
import RouteDocumentsPrinted from './event/routeDocumentsPrinted/RouteDocumentsPrinted';
import RouteAssignedToPreparer from './event/RouteAssignedToPreparer';
import RouteAssignedToTrolley from './event/routeAssignedToTrolley/RouteAssignedToTrolley';
import RouteTrolleyAssignedToPicker from './event/RouteTrolleyAssignedToPicker';
import CachedRouteAssigned from './event/cachedRouteAssigned/CachedRouteAssigned';

class Route extends AggregateRoot {
  readonly id?: string;
  readonly preparerId?: string;
  readonly trolleyId?: string;
  readonly pickerId?: Picker;
  readonly orderId?: string;
  readonly segment?: string;

  private constructor({
    id,
    preparerId,
    trolleyId,
    pickerId,
    orderId,
    segment,
  }: {
    id?: string;
    preparerId?: string;
    trolleyId?: string;
    pickerId?: string;
    orderId?: string;
    segment?: string;
  }) {
    super();
    this.id = id;
    this.preparerId = preparerId;
    this.trolleyId = trolleyId;
    this.pickerId = pickerId;
    this.orderId = orderId;
    this.segment = segment;
  }

  static assignPreparer(preparerId: string, segment: string): Route {
    console.debug('Assigning preparer', preparerId, 'to segment', segment);
    const route = new Route({ preparerId: preparerId, segment: segment });
    route.record(new RouteAssignedToPreparer());
    return route;
  }

  static assignRouteToTrolley(routeId: string, trolleyId: string): Route {
    const route = new Route({ id: routeId, trolleyId: trolleyId });
    route.record(new RouteAssignedToTrolley());
    return route;
  }

  static assignRouteTrolleyToPicker(trolleyId: string, pickerId: Picker): Route {
    const route = new Route({ trolleyId: trolleyId, pickerId: pickerId });
    route.record(new RouteTrolleyAssignedToPicker());
    return route;
  }

  static printRouteDocuments(routeId: string): Route {
    const route = new Route({ id: routeId });
    route.record(new RouteDocumentsPrinted());
    return route;
  }

  static assignCachedRoute(orderId: string): Route {
    console.debug('Assigning cached route', orderId);
    const route = new Route({ orderId });
    route.record(new CachedRouteAssigned());
    return route;
  }
}

export type Picker = string;

export default Route;
