import Route from 'core/domain/model/route/write/Route';
import RouteDocument from 'core/domain/model/route/write/RouteDocument';
import RouteNotFoundToPrepareError from 'core/domain/model/route/write/RouteNotFoundToPrepareError';
import AssignPreparerToRouteFailedError from 'core/domain/model/route/write/AssignPreparerToRouteFailedError';
import RouteExistingWithTrolleyError from 'core/domain/model/route/write/RouteExistingWithTrolleyError';
import RouteNotPreparedToAssignTrolleyError from 'core/domain/model/route/write/RouteNotPreparedToAssignTrolleyError';
import RouteWithTrolleyError from 'core/domain/model/route/write/RouteWithTrolleyError';
import TrolleyBarcodeBadFormatError from 'core/domain/model/route/write/TrolleyBarcodeBadFormatError';
import RouteWriter from 'core/domain/model/route/write/RouteWriter';
import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import PrintLine from 'shared/domain/model/PrintLine';
import RouteNotFoundToAssignTrolleyError from 'core/domain/model/route/write/RouteNotFoundToAssignTrolleyError';
import DomainError from 'shared/domain/error/DomainError';
import RouteNotFoundToPrintError from 'core/domain/model/route/write/RouteNotFoundToPrintError';
import RouteNotFoundToCachedAssignError from 'core/domain/model/route/write/RouteNotFoundToCachedAssignError';
import CachedRouteAssignedToPickerError from 'core/domain/model/route/write/CachedRouteAssignedToPickerError';

class HttpRouteWriter implements RouteWriter {
  private static readonly BARCODE_BAD_FORMAT_ERROR = 'G-0002';

  private static readonly ASSIGN_TROLLEY_TO_PICKER_ERRORS: Record<
    number,
    (body?: Record<string, unknown>) => DomainError
  > = {
    [HttpErrorCodes.BAD_REQUEST]: (body?: Record<string, unknown>) =>
      body && body.code === HttpRouteWriter.BARCODE_BAD_FORMAT_ERROR
        ? new TrolleyBarcodeBadFormatError()
        : new RouteWithTrolleyError(),
    [HttpErrorCodes.CONFLICT]: () => new RouteWithTrolleyError(),
    [HttpErrorCodes.NOT_FOUND]: () => new RouteWithTrolleyError(),
  };

  private static readonly ASSIGN_ROUTE_TO_TROLLEY_ERRORS: Record<
    number,
    (body?: Record<string, unknown>) => DomainError
  > = {
    [HttpErrorCodes.BAD_REQUEST]: (body?: Record<string, unknown>) =>
      body && body.code === HttpRouteWriter.BARCODE_BAD_FORMAT_ERROR
        ? new TrolleyBarcodeBadFormatError()
        : new RouteExistingWithTrolleyError(),
    [HttpErrorCodes.CONFLICT]: () => new RouteNotPreparedToAssignTrolleyError(),
    [HttpErrorCodes.NOT_FOUND]: () => new RouteNotFoundToAssignTrolleyError(),
  };

  private static readonly PRINT_DOCUMENTS_IN_ROUTE_ERRORS: Record<
    number,
    (body?: Record<string, unknown>) => DomainError
  > = { [HttpErrorCodes.NOT_FOUND]: () => new RouteNotFoundToPrintError() };

  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  assignCachedRoute(route: Route, routeForType: string): Promise<void> {
    const body = {
      workOrderId: route.orderId,
      routeForType,
    };

    return this.httpClient.patch<void>('/v1/routes/assign', body).catch((error: HttpClientResponseError) => {
      if (error.status !== 200) {
        if (error.status === HttpErrorCodes.BAD_REQUEST && error.body?.code === 'D-0002') {
          throw new CachedRouteAssignedToPickerError();
        }
        throw new RouteNotFoundToCachedAssignError();
      }
    });
  }

  assignPreparerToRoute(route: Route): Promise<void> {
    const body = {
      preparerId: route.preparerId,
      segment: route.segment,
    };

    return this.httpClient.patch<void>('/v2/routes', body).catch((error: HttpClientResponseError) => {
      if (error.status === HttpErrorCodes.CONFLICT) {
        throw new RouteNotFoundToPrepareError();
      } else {
        throw new AssignPreparerToRouteFailedError();
      }
    });
  }

  assignRouteToTrolley(route: Route): Promise<void> {
    const body = {
      trolleyId: route.trolleyId,
    };

    return this.httpClient.patch<void>(`/v2/routes/${route.id}`, body).catch((error: HttpClientResponseError) => {
      const domainErrorFn = error.status ? HttpRouteWriter.ASSIGN_ROUTE_TO_TROLLEY_ERRORS[error.status] : undefined;
      throw domainErrorFn ? domainErrorFn(error.body) : error;
    });
  }

  assignTrolleyRouteToPicker(route: Route): Promise<void> {
    const body = {
      pickerId: route.pickerId,
    };

    const assignTrolleyRouteToPickerEndpoint = `/v2/routes/trolleyId=${route.trolleyId}`;
    return this.httpClient
      .patch<void>(assignTrolleyRouteToPickerEndpoint, body)
      .catch((error: HttpClientResponseError) => {
        const domainErrorFn = error.status ? HttpRouteWriter.ASSIGN_TROLLEY_TO_PICKER_ERRORS[error.status] : undefined;
        throw domainErrorFn ? domainErrorFn(error.body) : error;
      });
  }

  printDocumentsInRoute(route: Route, printLine: PrintLine, routeDocuments: RouteDocument[]): Promise<void> {
    const body = {
      line: printLine.line,
      documents: routeDocuments.map((routeDocument) => routeDocument.document),
    };
    return this.httpClient
      .post<void>(`/cmd/v1/routes/${route.id}/print-documents`, body)
      .catch((error: HttpClientResponseError) => {
        const domainErrorFn = error.status ? HttpRouteWriter.PRINT_DOCUMENTS_IN_ROUTE_ERRORS[error.status] : undefined;
        throw domainErrorFn ? domainErrorFn(error.body) : error;
      });
  }
}

export default HttpRouteWriter;
