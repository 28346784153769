import { Command } from '@lookiero/messaging.js';

class AssignPreparer extends Command {
  public readonly preparerId: string;
  public readonly segment: string;

  public constructor(preparerId: string, segment: string) {
    super();
    this.preparerId = preparerId;
    this.segment = segment;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'AssignPreparer';
  }
}

export default AssignPreparer;
